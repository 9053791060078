setTimeout(() => {
    const headerContainer = document.querySelector('.header__container');
    const headerLogo = document.querySelector('.header__logo');
    const hamburgerMobile = document.querySelector('.hamburger');
    const languageHeader = document.querySelector('.header__language');
    const headerSearch = document.querySelector('.header__search');
    const headerSearchCloseIcon = document.querySelector('.header__mobile_search_close');
    const headerMobileSearchForm = document.querySelector('.header__mobile_search form');
    const headerMobileSearchInput = document.querySelector('.header__mobile_search input');
    const mobileSearchIcon = document.querySelector('.header__mobile_search_icon_box');

    function handleSearch() {
        mobileSearchIcon.addEventListener('click', () => {
            headerLogo.style.display = "none";
            mobileSearchIcon.style.display = "none";
            hamburgerMobile.style.display = "none";
            languageHeader.style.display = "none";
            headerMobileSearchForm.style.display = "flex";
            headerMobileSearchInput.style.display = "block";
            headerContainer.style.display = "flex";
            headerSearch.style.width = "80%";
        })

        headerSearchCloseIcon.addEventListener('click', () => {
            headerLogo.style.display = "block";
            mobileSearchIcon.style.display = "block";
            hamburgerMobile.style.display = "block";
            languageHeader.style.display = "block";
            headerMobileSearchForm.style.display = "none";
            headerContainer.style.display = "grid";
        })
    }

    handleSearch();
}, 2000)