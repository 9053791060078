const allFooterMenu = document.querySelectorAll('.footer__first_menu_mobile_title');

function toggleFooterMenu () {
    allFooterMenu.forEach((item) => {
        item.addEventListener('click', () => {
            const itemUl = item.nextElementSibling;
            if(itemUl.style.display === "block"){
                itemUl.style.display = "none";
            } else {
                itemUl.style.display = "block";
            }
        })
    })
}

toggleFooterMenu();