$(function() {
    $('.faq__single_title').on('click', function() {
        console.log('$(this)');
        if($(this).hasClass('active')){
            $(this).removeClass('active');
            $(this)
                .siblings('.faq__single_body')
                .slideUp(600)

            $('.faq__single_icon > svg')
                .removeClass('rotate_dropdown_icon')
        } else {
            $('.faq__single_icon > svg')
                .removeClass('rotate_dropdown_icon')
                $(this)
                    .find('svg')
                    .addClass('rotate_dropdown_icon')
                $('.faq__single_title').removeClass('active')
                $(this).addClass('active')
                $('.faq__single_body').slideUp(600);
                $(this)
                    .siblings('.faq__single_body')
                    .slideDown(600)
        }
    })
})