const showMoreBtn = document.querySelectorAll('.games_show_more');

const isGamesPage = window.location.href.includes('/games/');

function handleShowMore () {    
    showMoreBtn.forEach((showBtn) => {
        showBtn.addEventListener('click', () => {
            const showBtnDiv = showBtn.parentElement;
            const showBtnIcon = showBtn.querySelector('i');

            if(showBtnDiv.classList.contains('setHeight')) {
                showBtnDiv.classList.remove('setHeight');
                showBtnIcon.classList.add('fa-chevron-up');
            } else {
                showBtnDiv.classList.add('setHeight');
                showBtnIcon.classList.remove('fa-chevron-up');
            }
        })
    })
};

if(isGamesPage){
    handleShowMore();
}