$(document).ready(function () {

    $('.next').click(function () {
        var currentImage = $('.guidess__slider_single_item');
        var currentImageIndex = $('.guidess__slider_single_item.active').index();
        var nextImageIndex = currentImageIndex + 1;
        var nextImage = $('.guidess__slider_single_item').eq(nextImageIndex);
        currentImage.fadeOut(1000);
        currentImage.removeClass('active');
        if (nextImageIndex == ($('.guidess__slider_single_item:last').index() + 1)) {
            $('.guidess__slider_single_item').eq(0).fadeIn(1000);
            $('.guidess__slider_single_item').eq(0).addClass('active');
        } else {
            nextImage.fadeIn(1000);
            nextImage.addClass('active');
        }
    });

    $('.prev').click(function () {
        var currentImage = $('.guidess__slider_single_item.active');
        var currentImageIndex = $('.guidess__slider_single_item.active').index();
        var prevImageIndex = currentImageIndex - 1;
        var prevImage = $('.guidess__slider_single_item').eq(prevImageIndex);


        currentImage.fadeOut(1000);
        currentImage.removeClass('active');
        prevImage.fadeIn(1000);
        prevImage.addClass('active');
    });
});