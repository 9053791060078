// This is the js file for the whole website
$(window).on('load', function() {
  $('.es_subscription_form.es_shortcode_form .es_subscription_form_submit').prop( "disabled", true ).addClass('disabled');
  if($('.es_subscription_form.es_shortcode_form').length){

    const form = $('.es_subscription_form.es_shortcode_form');
    const html = '<div class="es_subscription_form_agree">' +
        '<input id="form_agree_cheker" name="form_agree_cheker" class="es_subscription_form_agree_cheker" type="checkbox">' +
        '<label for="form_agree_cheker">I confirm that I am over 18 and consent to receive marketing messages in line with the OB <a href="/privacy">Privacy Policy</a></label>'+
        '</div>';
    form.append(html);
  }
  $('.es_subscription_form.es_shortcode_form').on('change', '.es_subscription_form_agree_cheker', function (){
    $('.es_subscription_form.es_shortcode_form .es_subscription_form_submit').prop( "disabled", false ).removeClass('disabled');
    $(this).prop( "disabled", true );
    // console.log('found one');
  })

  $('.list_of_reviews__container_search').on('input', 'input' ,function (){
    let search_str = $(this).val().toLowerCase();
    let post_name = '';
    let max_search = 18;
    let x = 0;
    //console.log($(this).val());
    $('#list_of_reviews .list_of_reviews__container_feed_item').each(function (){
      $(this).removeClass('active');
      if ($(this).data('post_name').toLowerCase().includes(search_str) && x < max_search){
        $(this).addClass('active');
        console.log($(this).data('post_name'));
        //show_review_from(1,'.search_result');
      }
      x++;
    })
  })
  $('.list_of_reviews__container_pagination_page_dot').on('click' ,function (){
    if($(this).hasClass('clickable')){
      let offset = $(this).data('offset');
      $(this).parent().parent().data('current_page', offset );
      show_review_from(offset);
    }
  })
  $('.list_of_reviews__container_pagination_arrow').on('click' ,function (){
    let offset = $(this).data('offset');
    let current_page = $(this).parent().parent().data('current_page');
    let page = current_page + offset;
    if( page >= 1 && page <= 5){
      $(this).parent().parent().data('current_page', page);
      show_review_from(page);
    }
    //console.log(offset + ' - ' + page);
  })

  function show_review_from(offset, search_class = ''){
    let from_post_id = offset * 18 - 18;

    $('#list_of_reviews .list_of_reviews__container_feed_item' + search_class).each(function (){
      if( parseInt($(this).data('post_id')) < from_post_id || parseInt($(this).data('post_id')) > ( from_post_id + 17)  ) {
        $(this).removeClass('active');
      } else {
        $(this).addClass('active');
      }
    });
    $('.list_of_reviews__container_pagination_page_dot').each(function (i, o){
      $(this).removeClass('clickable');
      if($(this).data('offset') !== offset ){
        $(this).addClass('clickable');
      }
    });
    $('.list_of_reviews__container_search input').val('');
  }


  $('body').on('click', '.expand_button', function (){
    if($(this).parent().parent().hasClass('expand')){
      $(this).parent().parent().removeClass('expand');
    } else {
      $(this).parent().parent().parent().children().each(function (i, o){
        $(o).removeClass('expand');
      })
      $(this).parent().parent().addClass('expand');
    }
  })

  $('.tp-tab-content').click( function (){
    let slide = $(".banner").find("rs-slide[data-key='" + $(this).parent().data('key') + "']");
    console.log($(slide).data('link'));

    if (typeof($(slide).data('link')) != 'undefined'){
      window.location.replace($(slide).data('link'));
    }
  })
  function animate_all_new_score_circle(){
    //console.log($('.scoreCircle-percentage .arc').length);
    if($('.scoreCircle-percentage .arc').length){
      var time = 100;
      $('.scoreCircle-percentage .arc').each(function (id, obj){
        setTimeout( function(){ start_stat_rotation(obj); }, time)
        time += 100;
      })
    }
  }

  animate_all_new_score_circle();

  function start_stat_rotation(obj){
    if($(obj).not('.animation_done')){
      $(obj).attr('stroke-dashoffset', $(obj).data('target'));
      $(obj).not('.animation_done').addClass('animation_done');
    }
  }

  $( ".header__container .header__search" ).hover(
      function() {
        $( this ).parent().addClass('search_expand');
      }, function() {
        $( this ).parent().removeClass('search_expand');
      }
  );

  /////////////////////////////////
  //////////// Tabs ///////
  $('body').on('click', '.nav-tab', function(event){
    event.preventDefault();

    const targ = $(this).data('target');
    const page = $(this).data('page');
    $(this).parent().parent().find('.nav-tab').removeClass('nav-tab-active');
    $(this).parent().parent().find('.tab-target').removeClass('tab-target-active');
    $(this).addClass('nav-tab-active');
    $('#'+targ).addClass('tab-target-active');
    const new_url = window.location.href.split('?')[0] + '?' + targ + '=' + page;
    ChangeUrl(targ, new_url);
    //console.log(url.split('?')[0] );
  });

  function ChangeUrl(title, url) {
    if (typeof (history.pushState) != "undefined") {
      var obj = { Title: title, Url: url };
      history.pushState(obj, obj.Title, obj.Url);
    } else {
      alert("Browser does not support HTML5.");
    }
  }

  function gather_filtering_and_sorting_data(){
    let fields = {};
    $('.filter_conntent_block select').each(function (i, o){
      console.log($(o).attr('id') + ' = ' + $(o).val());
      if ($(o).val() != 0){
        fields[$(o).attr('id')] = $(o).val();
      }
    })
    let is_sort_asc = $('.filter_conntent_block .filter_conntent_block-sort-order').first().data('sort_asc');
    if(is_sort_asc){
      fields['order'] = 'asc';
    }
    console.log(fields);
    return fields;
  }

  $('.filter_conntent_block .filter_conntent_block-sort-order').click(function () {
    $(this).data('sort_asc', ! $(this).data('sort_asc'));
    if ($(this).data('sort_asc')){
      $(this).children().first().attr('class','fa fa-sort-amount-asc');
    } else {
      $(this).children().first().attr('class','fa fa-sort-amount-desc');
    }
    console.log($(this).data('sort_asc'));
    reload_filtered_content($(this), true);
  })

  $('.filter_conntent_block select').change(function (){
    reload_filtered_content($(this));
  })

  function reload_filtered_content(obj, force = false){
    let filter = gather_filtering_and_sorting_data();
    //console.log();
    if($(filter).length || force){
      let data = {
        action: 'load_more_contend',
        filters: filter,
        post_type: 'games',
        item_count: main_script_params.posts_per_page
      }
      load_more_content(data, obj.closest('.inner').find('.load_more_resources').first(), false);
    }
  }

    /// Load more for category page
    $('#casinos .inner').on('click', '.casino_search_load_more', function(){
      let item_count = parseInt($(this).data('item_count'));
      let data = {
        action: 'load_more_contend',
        search_category: $(this).data('category_name'),
        filters: gather_filtering_and_sorting_data(),
        post_type: $(this).data('post_type'),
        order: $(this).data('order'),
        exclude: $(this).data('exclude'),
        item_count: item_count,
      }
      //console.log(data);
      load_more_content(data, $(this));
    })

    /// Content loader function
    function load_more_content(data, loader, load_more = true){
      let time = 0;
      if(!load_more){
        var button_group = '<div class="load_more_resources"></div>' + $(loader).parent().html();
      }
      loader.parent().toggleClass('loading');
      jQuery.ajax({
        url: main_script_params.ajaxurl,
        data: data,
        dataType: 'JSON',
        success:function(post_data){
          if(post_data.success ) {
            console.log(post_data.count);
            if(!load_more){
              let content = post_data.count != 0 ? post_data.result.join('') : 'Found nothing';
              $(loader).parent().html(content + '<div class="load_more_resources"></div>');
            } else {
              $.each(post_data.result, function(key, value){

                setTimeout( function(){
                  loader.parent().parent().find('.load_more_resources').before(value);
                }, time)
                time += 100;
              });

              loader.data('item_count', data.item_count + parseInt(main_script_params.posts_per_page));

              if (!parseInt(post_data.more)){
                loader.parent().toggleClass('loading');
                loader.toggleClass('hide');
              } else {
                loader.parent().toggleClass('loading');
              }
            }

          } else {
            console.log('Server error');
          }

          setTimeout( function(){
            animate_all_new_score_circle();
          }, time )
        },
        error: function(errorThrown){
          console.log(errorThrown);
          loader.parent().toggleClass('loading');
        }
      });
    }

    // cut content from guides description
    $('.latest_guides__paragraph p').each(function (obj, text){
      $(this).text( jQuery.trim($(text).first().text()).substring(0, 150)
          .split(" ").slice(0, -1).join(" ") + " ..." );
    })

    // function  for the scroll to top button
    $('.scroll-to-top-btn').css('display', 'none');
    $(window).on('scroll',function () {
      if ($(this).scrollTop() > 100) {
          $('.scroll-to-top-btn').fadeIn();
      } else {
          $('.scroll-to-top-btn').fadeOut();
      }
    });
    
    $('.scroll-to-top-btn').on('click',function () {
      $("html, body").animate({
          scrollTop: 0
      }, 200);
      return false;
    });

    // function to display and hide details section in casino reviews
    $('.single_casino__details_container_header').on('click', function() {
      $('.single_casino__detail_single_icon').toggleClass('rotate_dropdown_icon');
      $('.single_casino__details_container_body').slideToggle(400);
    })

    // the "rotate_detail_icon can be found in /sccs/repeat/repeat.scss"
    // the remaining classes can be found in the /scss/single-casinos/single-casinos.scss

    // function for toggling mobile nav menu
    // $('.hamburger__icon .fa-bars').on('click', function() {
    //   $(this)
    //     .toggleClass('fa-times')
    //     $('.header__mobile_menu')
    //       .toggleClass('show')
    // })

    // Section that controls the stick anchor links menu on the home page and other pages.

    let stickyOffset = $('.anchor__menu').length ? $('.anchor__menu').offset().top : 0;

    $(window).on('scroll',function(){
      var sticky = $('.anchor__menu'), 
          scroll = $(window).scrollTop();

      if (scroll >= stickyOffset) sticky.addClass('anchor-menu-fixed');
      else sticky.removeClass('anchor-menu-fixed');
    });
})