const allDesktopListedItems = document.querySelectorAll('.header__menu ul li');
const allMobileListedItems = document.querySelectorAll('.header__mobile_menu ul li');

// Function for the dropdown menus
function toggleDropDown() {
    // Add a dropdown Icon to an li whenever a sub menu is added to it

    $('.hamburger__icon .fa-bars').on('click', function () {
        $(this)
            .toggleClass('fa-times')
        $('.header__mobile_menu')
            .toggleClass('show')
    })

    allDesktopListedItems.forEach((item) => {
        if (item.classList.contains('menu-item-has-children')) {
            return item.innerHTML += "<span class='dropdown_icon'><i class='fas fa-caret-down'></i></span>"
        }
    });

    // Add a dropwdown icon to an a tag whenever a submenu is added
    allMobileListedItems.forEach(function (singleListItem) {
        // find only the li with class "menu-item-has-children"
        if (singleListItem.classList.contains('menu-item-has-children')) {
            // select the a tag inside that li
            const singleItemLink = singleListItem.querySelector('a')
            const singleItemUl = singleItemLink.nextElementSibling;
            // add a dropdown icon inside that 'a' tag
            singleItemLink.innerHTML += "<div class='new_drop_down'><i class='fas fa-caret-down'></i></div>";
            const singleItemDropDown = singleItemLink.querySelector('.new_drop_down');

            // prevent default when the icon is clicked
            singleItemDropDown.addEventListener('click', function (e) {
                e.stopPropagation();
                e.preventDefault();

                // toggle display when itemADiv is clicked
                if (singleItemUl.style.display === 'block') {
                    singleItemUl.style.display = "none";
                } else {
                    singleItemUl.style.display = "block"
                }
            })
        }
    });
}

setTimeout(() => {
    toggleDropDown();
}, 3000);